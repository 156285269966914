import React, { useState } from "react";
import SignatureFormatter from "./components/SignatureFormatter";
import styled from "@emotion/styled";
import "./styles.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  @media (min-width: 860px) {
    flex-direction: row;
  }
`;

const FormContainer = styled.div`
  flex: 1 1 calc(50% - 50px);
  background-color: #5801FF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
`;

const Divider = styled.div`
  height: 20px;
  flex: 1 1 20px;
  align-self: stretch;
  background-color: #5801FF;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    fill: #fff;
  }
  @media (min-width: 860px) {
    height: auto;
    flex: 1 1 100px;
    background-color: transparent;
    svg {
      fill: #5801FF;
    }
  }
`;

const ResultContainer = styled.div`
  flex: 1 1 calc(50% - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
`;

const CornerIcon = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem;
  &:before {
    content: "";
    position:absolute;
    width: 250px;
    height: 150px;
    bottom: 0;
    right: 0;
    background-color: #5801FF;
    transform: rotate(-45deg);
    transform-origin: right top;
    z-index: -1;
    transition: all 0.3s ease-out;
  }

  &:hover {
    &:before {
      transform: translateY(-10px) rotate(-45deg);
    }
  }
    
`;

const Logo = styled.a`
  color: #fff;
  svg {
    height: 100%;
    fill: #fff;
    transition: all 0.2s ease-out;
  }
  height: 50px;
  display: block;
  &:hover {
    svg {
      fill: #FF554E;
    }
  }
`;

const UserEditor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;

  font-family: "Calibre";
  font-weight: 400;
  font-size: 1rem;
  color: #32323c;
  line-height: 1.5625rem;

  margin: 2rem;
`;

const Header = styled.h1`
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 2rem;
  padding: 0;
  font-family: "National 2 Narrow";
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  line-height: 2.25rem;
  font-size: 2.5rem;
`;

const InputWrapper = styled.div`
  margin: 1rem 0;
`;

const InputLabel = styled.label`
  color: #ffffff;
  display: inline-block;
  margin-top: 0.5rem;
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  width: 100%;
  font-size: 1.125rem;
`;

const InputElement = styled.input`
  border: 1px solid #fff;
  font-size: 1.125rem;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  padding: 12px 14px 12px;
  &:focus {
    outline: none;
  }
`;

const Subtitle = styled.div`
  max-width: 450px;
  color: #fff;
`;

const Description = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  ol {
    padding-left: 1rem;
  }
  h3 {
    color:  #FF554E;
  }
  code {
    background-color: #5801FF;
    border-radius: 3px;
    padding: 2px 8px 3px;
    color: #fff;
    font-size: 1rem;
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  }
`;

const userData = {
  name: "Mohammed Fahsi",
  job: "Front-end Developer",
  availability: "Every weekday apart from wednesday",
  phone: "+31612345678"
};

export default function App() {
  const [user, setUser] = useState(userData);

  const userInfoChange = e => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  return (
    <Wrapper>
      <FormContainer>
        <Header>
          signature generator
        </Header>
        <Subtitle>
          Vul hieronder je gegevens in om je eigen Greenberry email-signature snel en eenvoudig to genereren.<br/>
          <br/>
          <strong>Alle velden met een * zijn verplicht!</strong> Als een veld niet van toepassing is kun je deze verwijderen door het veld leeg te maken.
        </Subtitle>
        <UserEditor>
          <InputWrapper>
            <InputLabel>Voornaam & Achternaam *</InputLabel>
            <InputElement
              name="name"
              onChange={userInfoChange}
              value={user.name}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Functie *</InputLabel>
            <InputElement
              name="job"
              onChange={userInfoChange}
              value={user.job}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Beschikbaarheid</InputLabel>
            <InputElement
              name="availability"
              onChange={userInfoChange}
              value={user.availability}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Prive telefoon</InputLabel>
            <InputElement
              name="phone"
              onChange={userInfoChange}
              value={user.phone}
            />
          </InputWrapper>
        </UserEditor>
      </FormContainer>
      <Divider>
        <svg viewBox="0 0 10 10" preserveAspectRatio="none">
          <polygon points="0,0 0,10 10,10" />
        </svg>
      </Divider>
      <ResultContainer>
        <Description>
          <h3>Gebruiksaanwijzing</h3>
          <ol>
            <li>Selecteerd de onderstaande signature in zijn volledigheid.</li>
            <li>Kopieer deze dmv de toetsencombinatie <code>CTRL + C</code> op Windows, of <code>Command + C</code> op OSX.<br/> <strong>of</strong> gebruik je rechtermuisknop en klik op copy/kopiëren. </li>
            <li>Ga via deze <a href="https://mail.google.com/mail/u/0/#settings/general" target="_blank" rel="noopener noreferrer">link</a> naar je gmail settings en plak je signature in het daarvoor bedoelde signature textveld.</li>
            <li>Geniet van je greenberry signature <span role="img" aria-label="success">🥳</span></li>
          </ol>
          <small>Gebruik je een andere email-client? Volg dan de instructies voor de desbetreffende email-clients hier <a href="https://support.office.com/en-us/article/create-and-add-a-signature-to-messages-8ee5d4f4-68fd-464a-a1c1-0e1c80bb27f2" target="_blank" rel="noopener noreferrer">Outlook</a>, <a href="https://support.apple.com/guide/mail/create-and-use-email-signatures-mail11943/mac" target="_blank" rel="noopener noreferrer">Apple Mail</a></small>
        </Description>
        <SignatureFormatter user={user} />
      </ResultContainer>
      <CornerIcon>
        <Logo href="https://greenberry.nl">
          <svg viewBox="0 0 181.43 156.83" xmlns="http://www.w3.org/2000/svg">
            <path d="m172.16 61.57-11.9-20.29h-16l8.45-14.34-11.91-20.28h-23.9l-6.9 13.44-7-13.44h-23.9l-7.77 13.25-8-13.25h-23.9l-11.9 20.28 8.47 14.34h-16l-11.93 20.29 12.1 20.53h15.31l-8.21 14 12.1 20.53h15.63l-8 13.55 12.1 20.54h23.7l7.24-12.23 7.23 12.23h23.71l12.09-20.54-8-13.55h15.66l12.27-20.54-8.21-14h15.32zm-35.82-38.49a4.44 4.44 0 1 1 -3.54-5.19 4.44 4.44 0 0 1 3.54 5.19z" />
          </svg>
        </Logo>
      </CornerIcon>
    </Wrapper>
  );
}
